import * as React from "react";
import * as ReactDOM from "react-dom";
require('es6-promise').polyfill();

import {HomeNossosHoteis} from "./HomeNossosHoteis";

ReactDOM.render(
    <HomeNossosHoteis compiler="TypeScript" framework="React" />,
    document.getElementById("nossos-hoteis")
);
