import * as fetch from "isomorphic-fetch";

export class RequestError extends Error {
  response: object;

  constructor(msg: string, response: object) {
    super(msg);
    this.response = response;
  }
}

function parseJSON(response:any) {
  return response.json();
}

function checkStatus(response:any) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }

  throw new RequestError(response.statusText, response);
}

function checkErrorResponse(response:any) {
  const { error, data, backtrace } = response;
  if (error) {
    console.log(`Error response: ${error}`, data, backtrace);
    // notification.error({ message: 'Ocorreu um erro inesperado :(' });
  }

  return response;
}

export function request(url:string, options?:object) {
  const authToken = localStorage.getItem('authToken');
  const headers:any = {
    'Content-Type': 'application/json',
  };

  if (authToken) {
    headers.Authorization = authToken;
  }

  return fetch(url, { ...options, headers })
    .then(checkStatus)
    .then(parseJSON)
    .then(checkErrorResponse)
    .then(data => ({ ...data }))
    .catch(exception => ({ exception }));
}

export function post(url:string, data?:any):any {
  return request(url, {
    method: 'post',
    body: JSON.stringify(data),
  });
}

export function get(url:string):any {
  return request(url);
}
