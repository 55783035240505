import * as React from "react";
import {post} from "../lib/request";
import {numberFormat} from "../lib/utils";


declare global {
  interface Window {
    gettext: (t:string) => string;
    language: string;
  }
}


interface IProps {
  compiler: string;
  framework: string;
}

interface IState {
  hoteis: any[];
  hoteisSelecionados: any[];
  estados: any[];
  estadoSelecionado: string;

  precos: any[];
  precoSelecionado: number;

  termo: string;

  categorias: any[];
  categoriasSelecionadas: any;

  loading: boolean;
  paginaAtual: any;
  itensPorPagina: any;
  indiceInicio: any;
  indiceFim: any;
}

interface IFiltros {
  termo: string;
  estadoSelecionado: string;
  precoSelecionado: number;
  categoriasSelecionadas: any;
}

export class HomeNossosHoteis extends React.Component<IProps, IState> {
 
  constructor(props: IProps) {
    super(props);

    
  
    this.state = {
      hoteis: [],
      hoteisSelecionados: [],
      estados: [],
      estadoSelecionado: "",
      precos: [],
      precoSelecionado: 0,
      termo: "",
      categorias: [],
      categoriasSelecionadas: {},
      loading: false,
      paginaAtual: 1,
      itensPorPagina: 4,
      indiceInicio: 0,
      indiceFim: 4,
    }
  };

  getFiltros():IFiltros {
    const {termo, estadoSelecionado, precoSelecionado, categoriasSelecionadas} = this.state;

    const cats = Object.keys(categoriasSelecionadas).filter((i) => categoriasSelecionadas[i]);
    return {termo, estadoSelecionado, precoSelecionado, categoriasSelecionadas: cats};
  }

  componentDidMount() {
    this.reload({...this.getFiltros()}, true);
  }
  
  reload = (filtros:IFiltros, inicial:boolean=false) => {
    this.setState({
      loading: true,
    });

  
    post(`/api/home/load?lang=${window.language}`, filtros)
      .then(({success, data}: any) => {
        if (!success) {
          this.setState({
            loading: false,
          });
          console.log("Erro carregando dados...");
        }

        const state:any = {
          loading: false,
          estados: data.estados,
          hoteis: data.hoteis,
          categorias: data.categorias,
          precos: data.precos,
        };

        // defaults
        const categoriasSelecionadas:any = {};
        if (inicial) {
          data.categorias.forEach((i:number) => {
            categoriasSelecionadas[i] = true;
          });

          var hoteisSelec:any = [];
          data.hoteis.forEach((hotel:any,index:any)=>{
              if(index < 4){
                hoteisSelec.push(hotel)
              }
          })
          state["hoteisSelecionados"] = hoteisSelec;
          state["precoSelecionado"] = data.precos[0];
          state["categoriasSelecionadas"] = categoriasSelecionadas;

          
          state["paginaAtual"] = 1;
          state["itensPorPagina"] = 4;
          state["indiceInicio"] = 0;
          state["indiceFim "] = 0;
          
        }

        this.setState(state);
      });
  };
  
  onChangeEstado = (e:any) => {
    this.setState({
      estadoSelecionado: e.target.value,
    });
  };
  
  onClickCategoria = (e:any) => {
    const {categoriasSelecionadas} = this.state;
    categoriasSelecionadas[e.target.value] = e.target.checked;
    this.setState({
      categoriasSelecionadas,
    });
  };
  
  onChangeTermo = (e:any) => {
    this.setState({
      termo: e.target.value,
    });
  };
  
  onChangePreco = (e:any) => {
    this.setState({
      precoSelecionado: e.target.value,
    });
  };
  
  onClickLimpar = () => {
    const categoriasSelecionadas:any = {};
    this.state.categorias.forEach((i: number) => {
      categoriasSelecionadas[i] = true;
    });

    const termo = "";
    const precoSelecionado = this.state.precos[0];
    const estadoSelecionado = "";

    this.setState({
      termo,
      categoriasSelecionadas,
      precoSelecionado,
      estadoSelecionado,
    });

    this.reload({termo, categoriasSelecionadas, precoSelecionado, estadoSelecionado});
  };
  
  onClickBuscar = () => {
    this.reload(this.getFiltros());
  };


  
  
  onClickPagina = (acao:any,pagina:any) => {
    
    if(acao == "next"){
      var indiceInit = this.state.indiceInicio + 4;
      var indiceEnd = this.state.indiceFim + 4;
      var hoteisPaginaAtual = this.state.hoteis.slice(indiceInit, indiceEnd);
      console.log("Hoteis atual",this.state.hoteis)
      console.log("Hoteis pagina",hoteisPaginaAtual)
      console.log("Hoteis indiceInit",indiceInit)
      console.log("Hoteis indiceEnd",indiceEnd)
      console.log("Hoteis indiceEnd2",this.state.indiceFim)
      this.setState({
        paginaAtual:this.state.paginaAtual + 1,
        hoteisSelecionados : hoteisPaginaAtual,
        indiceInicio : indiceInit,
        indiceFim : indiceEnd
      })
      

    }
    if(acao == "prev"){
      var indiceInit:any = this.state.indiceInicio - 4;
      var indiceEnd:any = this.state.indiceFim - 4;
      if(indiceInit < 0){
        indiceInit = 0;
      }
      if(indiceEnd < 4){
        indiceEnd = 4;
      }
      var hoteisPaginaAtual = this.state.hoteis.slice(indiceInit, indiceEnd);
      this.setState({
        paginaAtual:this.state.paginaAtual - 1,
        hoteisSelecionados : hoteisPaginaAtual,
        indiceInicio : indiceInit,
        indiceFim : indiceEnd
      })
      

    }



  };

  onKeyPressTermo = (event:any) => {
    if (event.key == "Enter") {
      this.onClickBuscar();
    }
  };


  render() {
    return (
      <div className="container-fluid" id="nossos-hoteis">
        <div className="row">
          <div className="col-sm-12">
            <h1 className="titulo-secao">
              {window.gettext('Nossos hotéis')}
            </h1>
          </div>
        </div>
        <div className="container" style={{position: "relative"}}>
          <div className="lds-css ng-scope" style={{display: this.state.loading ? 'block' : 'none'}}>
            <div style={{width: "100%", height: "100%"}} className="lds-eclipse">
              <div/>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4">
              <div className="filtros">
                <div className="filtros-titulo mt-0 titulo-busca">
                  {window.gettext('Procure seu hotel preferido')}
                </div>
                <div className="input-group caixa-busca">
                  <input className="form-control" type="text" placeholder={window.gettext('Busca')}
                         value={this.state.termo}
                         onChange={this.onChangeTermo}
                         onKeyPress={this.onKeyPressTermo}
                  />
                </div>

                <div className="titulo-outros-campos filtros-titulo">
                  {window.gettext('Ou utilize os campos abaixo')}
                </div>
                <div className="caixa-outros-campos">
                  <div className="filtros-titulo mt-0">
                    {window.gettext('Localização')}
                  </div>
                  <div className="form-group">
                    <select className="form-control" id="regiao"
                            value={this.state.estadoSelecionado}
                            onChange={this.onChangeEstado}>
                      <option value="">--{window.gettext('Indiferente')}</option>
                      {
                        this.state.estados.map((l:any) => (
                          <optgroup key={l.cidade} label={l.cidade}>
                            {
                              l.bairros.map((i:any) => (
                                <option key={i} value={`${l.cidade}/${i}`}>{i}</option>
                              ))
                            }
                          </optgroup>
                        ))
                      }
                    </select>
                  </div>

                  <div className="filtros-titulo">
                    {window.gettext('A partir de')}
                  </div>
                  <div className="form-group">
                    <label htmlFor="formControlRange" className="label-preco">
                      R$ {numberFormat(this.state.precoSelecionado, 0)}
                    </label>
                    <input type="range" className="form-control-range"
                           min={this.state.precos[0]}
                           max={this.state.precos[1]}
                           onChange={this.onChangePreco}
                           value={this.state.precoSelecionado}
                    />
                  </div>

                  <div className="filtros-titulo">
                    {window.gettext('Categoria')}
                  </div>
                  {
                    this.state.categorias.map((cat:number) => (
                      <div className="form-group form-check" key={cat}>
                        <input className="form-check-input"
                               type="checkbox"
                               id={`${cat}_estrelas`}
                               value={cat}
                               onChange={this.onClickCategoria}
                               checked={this.state.categoriasSelecionadas[cat]}
                        />
                        <label className="form-check-label" htmlFor={`${cat}_estrelas`}>
                          {cat} {window.gettext('estrelas')}
                        </label>
                      </div>
                    ))
                  }

                  <div className="row">
                    <div className="col-6">
                      <button className="btn btn-secondary" style={{width: "100%"}}
                              onClick={this.onClickLimpar}>
                        {window.gettext('Limpar')}
                      </button>
                    </div>
                    <div className="col-6">
                      <button className="btn btn-success" style={{width: "100%"}}
                              onClick={this.onClickBuscar}>
                        {window.gettext('Buscar')}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-8">
              {
                !this.state.loading &&
                  <div>
                    {
                      this.state.hoteis.length === 0 &&
                      <div>
                        Se você não encontrou o hotel desejado, ligue para a nossa Central de
                        Reservas: + 55 (21) 2195-7800. Vamos ajudar você a escolher a melhor opção.
                      </div>
                    }

                    {this.state.hoteisSelecionados.length >= 0 && 
       
                    this.state.hoteisSelecionados.map((hotel) => (
                      <div className="hotel" key={hotel.id}>
                        
                        <a href={`/hotel/${hotel.tag}/`}><div className="imagem"
                             style={{backgroundImage: `url(${hotel.conteudos["imagem-topo"].arquivo_url})`}}/></a>
                        <div style={{flexGrow: 2}} className="dados">
                          <div className="nome">
                            <a href={`/hotel/${hotel.tag}/`}>
                              <div>{hotel.nome}</div>
                              <div className="stars">
                                {[...Array(hotel.categoria).keys()].map((i: any) => (
                                <span key={i}>&#9733;</span>
                                ))}
                              </div>
                      
                            </a>
                          </div>
                          <div className="descricao">
                            {hotel.conteudos.titulo.dados}
                          </div>
                          <div className="features container">
                            <div className="row">
                              {
                                hotel.recursos.map((recurso: any) => (
                                  <div key={recurso.id} className="d-flex align-items-center">
                                    <div className="col-sm-auto" style={{padding: 0}}>
                                      <img src={recurso.icone} alt={recurso.nome}
                                           className="icone"/>
                                    </div>
                                    <div className="col feature" style={{padding: 0}}>
                                      {window.gettext(recurso.nome)}
                                    </div>
                                  </div>
                                ))
                              }

                                  {hotel.tour360 && 
                                  <a href={hotel.tour360} target="_blank" key= "Tour Virtual" className="d-flex align-items-center">
                                    <div className="col-sm-auto" style={{padding: 0}}>
                                      <img src="/static/img/icones/360-degress2.png" alt="Tour Virtual"
                                           className="icone"/>
                                    </div>
                                    <div className="col feature" style={{padding: 0}}>
                                      {window.gettext("Tour Virtual")}
                                    </div>
                                   
                                  </a>
                                }

                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                    }
         
                    {this.state.hoteis.length >= 5 && 
                    
                    <div className="pagHoteis" style={{display:"grid",width:'100%',justifyContent:(this.state.paginaAtual > 1) ? "normal" : "end"}}>
         
                    <div style={{display:"inline-flex",gap:"5px",width:'100%',justifyContent:(this.state.paginaAtual > 1) ? "space-between" : "end"}}>
                      
                      {this.state.paginaAtual > 1 &&
                      <div
                        onClick={() => this.onClickPagina("prev","1")}
                        style={{cursor:"pointer",marginBottom:"6px",display:"inline-flex",gap:"3px",alignItems:"center"}}
                      >
                          
                          <div>&lt; Voltar</div>
                      </div>
                      }
                      {this.state.indiceFim < this.state.hoteis.length &&
                      <div
                        onClick={() => this.onClickPagina("next","1")}
                        style={{cursor:"pointer",marginBottom:"6px",display:"inline-flex",gap:"3px",alignItems:"center"}}
                      >
                          <div>Avançar &gt;</div>
                        
                          
                      </div>
                        }
                    </div>
                    </div>
                    }     

                  </div>
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

