/**
 * Ajustar quebras de texto trocando \n por <br>
 * @param texto
 * @returns {*}
 */
export function ajustarQuebras(texto:string) {
  if (!texto) {
    return {
      __html: '',
    };
  }

  return {
    __html: texto.replace(/\n/g, '<br>'),
  };
}

export function formatBytes(bytes:number, decimals:number) {
  if (bytes === 0) {
    return '0 Bytes';
  }
  const k = 1024;
  const dm = decimals || 2;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export function numberFormat(num:number|string, decimalPlaces:number) {
  if (typeof num === 'string') {
    num = Number.parseFloat(num);
  }

  const dp = decimalPlaces !== undefined ? decimalPlaces : 2;
  return num.toFixed(dp).replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
}

export function toFloat(str:string) {
  let stringOk = str.replace(/\./g, '');
  stringOk = stringOk.replace(/,/g, '.');

  return Number.parseFloat(stringOk);
}

export function toInt(str:string) {
  let stringOk = str.replace(/\./g, '');
  stringOk = stringOk.replace(/,/g, '.');

  return Math.floor(Number.parseFloat(stringOk));
}
